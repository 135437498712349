header {
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#root hr {
    border: 2px solid rgb(244, 98, 58);
    width: 60px;
    margin-top: 0.5rem;
    opacity: 1;
}

h1 {
    color: white;
    font-size: 3.5rem !important;
    font-weight: 800 !important;
}

.general-info {
    background-color: white;
    margin-top: -100px;
    max-width: 80%;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px -5px;
}

.general-info .image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.general-info .text {
    padding: 5rem 4rem;
}

.general-info h2 {
    font-size: 2.2rem;
    font-weight: 700;
}

.general-info h4 {
    font-weight: 400;
    padding-bottom: 1.3rem;
}

.general-info p {
    color: #7c7c7c;
}

.places-interest {
    padding: 6rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.places-interest h2 {
    text-align: center;
}

.places-interest h5 {
    text-align: center;
    margin-top: 1rem;
    font-size: 1rem;
}

.places-interest p {
    font-size: 14px;
    text-align: justify;
    color: #7c7c7c;
}

.places-interest img {
    aspect-ratio: 1;
    object-fit: cover;
    width: 100%;
}

.places-interest .spot {
    padding: 0 2rem;
}

.not-found {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not-found h3 {
    color: white;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
}

@media (max-width: 425px) {
    .general-info .image {
        display: none;
    }

    .places-interest .row {
        flex-direction: column;
    }
}